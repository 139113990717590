// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
    background: #282828;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
        "cloud ringLisa mario"
        "cloud ringDaniel ."
        "keyboard keyboard keyboard";
    align-content: center;
}

svg {
    width: 100px;
    stroke: #fff;
}

.gridItem {
    position: relative;
}

.gridItem .MuiButton-root {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.gridCloud {
    background: #e7e7e7;
    height: 100vh;
}

.gridRingDaniel {
    height: 50vh;
}

.gridRingLisa {
    background: #f4842e;
    height: 50vh;
}

.gridMario {
    height: 50vh;
}

.gridKeyboard {
    background: #f4842e;
    height: 100vh;
}

@media (orientation: landscape)
and (max-height: 450px) {

    .gridCloud {
        background: #e7e7e7;
        height: 200vh;
    }

    .gridRingDaniel, .gridRingLisa, .gridMario {
        height: 100vh;
    }

    .gridKeyboard {
        background: #f4842e;
        height: 100vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kCAAkC;IAClC,uBAAuB;IACvB;;;oCAGgC;IAChC,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;;;IAGI;QACI,mBAAmB;QACnB,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,mBAAmB;QACnB,aAAa;IACjB;AACJ","sourcesContent":[".App {\n    text-align: center;\n    background: #282828;\n}\n\n.grid {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-template-rows: 1fr;\n    grid-template-areas:\n        \"cloud ringLisa mario\"\n        \"cloud ringDaniel .\"\n        \"keyboard keyboard keyboard\";\n    align-content: center;\n}\n\nsvg {\n    width: 100px;\n    stroke: #fff;\n}\n\n.gridItem {\n    position: relative;\n}\n\n.gridItem .MuiButton-root {\n    position: absolute;\n    bottom: 5px;\n    right: 5px;\n}\n\n.gridCloud {\n    background: #e7e7e7;\n    height: 100vh;\n}\n\n.gridRingDaniel {\n    height: 50vh;\n}\n\n.gridRingLisa {\n    background: #f4842e;\n    height: 50vh;\n}\n\n.gridMario {\n    height: 50vh;\n}\n\n.gridKeyboard {\n    background: #f4842e;\n    height: 100vh;\n}\n\n@media (orientation: landscape)\nand (max-height: 450px) {\n\n    .gridCloud {\n        background: #e7e7e7;\n        height: 200vh;\n    }\n\n    .gridRingDaniel, .gridRingLisa, .gridMario {\n        height: 100vh;\n    }\n\n    .gridKeyboard {\n        background: #f4842e;\n        height: 100vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
