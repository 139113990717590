import "./Cloud.css";
import {ReactElement} from "react";

function Cloud(): ReactElement {
    return (
        <div className="cloudWrapper">
            <div className="cloud">
                <div className="head">
                    <div className="neck"></div>
                    <div className="face">
                        <div className="leftEye"></div>
                        <div className="rightEye"></div>
                    </div>
                    <div className="hair">
                        <div className="streak1"></div>
                        <div className="streak2"></div>
                        <div className="streak3">
                            <div className="root"></div>
                        </div>
                        <div className="streak4"></div>
                        <div className="streak5"></div>
                        <div className="streak6"></div>
                        <div className="streak7"></div>
                        <div className="streak8">
                            <div className="root"></div>
                        </div>
                        <div className="streak9">
                            <div className="root"></div>
                        </div>
                    </div>
                </div>
                <div className="upperBody">
                    <div className="leftArm">
                        <div className="shoulder leftShoulder"></div>
                        <div className="ellbow leftEllbow"></div>
                        <div className="hand leftHand"></div>
                    </div>
                    <div className="rightArm">
                        <div className="shoulder rightShoulder"></div>
                        <div className="ellbow rightEllbow"></div>
                        <div className="hand rightHand"></div>
                    </div>
                    <div className="chest"></div>
                    <div className="belly"></div>
                </div>
                <div className="belt"></div>
                <div className="lowerBody">
                    <div className="leftLeg">
                        <div className="leftKnee"></div>
                        <div className="leftBoot"></div>
                    </div>
                    <div className="rightLeg">
                        <div className="rightKnee"></div>
                        <div className="rightBoot"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cloud;