import './App.css';
import '@fontsource/inter';
import Button from '@mui/joy/Button';
import Grid from '@mui/joy/Grid';
import Item from '@mui/joy/Grid';

import Cloud from "./components/Cloud/Cloud";
import Ring from "./components/Ring/Ring";
import Mario from "./components/Mario/Mario";
import Keyboard from "./components/Keyboard/Keyboard";

function App() {
    return (
        <div className="App">
            <div className="header">
                Only CSS: This is a personal art project from <a href="https://www.new-world.it">Daniel Hahn</a>
            </div>
            <Grid container sx={{flexGrow: 1}}>
                <Grid xs={12} md={6} xl={4}>
                    <Item className="gridItem gridCloud">
                        <Cloud></Cloud>
                        <PenButton
                            color="neutral"
                            href="https://codepen.io/Arthega/pen/LYMxZLO"
                        >
                        </PenButton>
                    </Item>
                </Grid>
                <Grid xs={12} md={6} xl={4}>
                    <Item className="gridItem gridRingLisa">
                        <Ring
                            type="lisa"
                        ></Ring>
                        <PenButton
                            color="neutral"
                            href="https://codepen.io/Arthega/pen/vYvggxj"
                        >
                        </PenButton>
                    </Item>
                </Grid>
                <Grid container xs={12} xl={4}>
                    <Item xs={12} md={6} xl={12} className="gridItem gridMario">
                        <Mario></Mario>
                    </Item>
                    <Item xs={12} md={6} xl={12} className="gridItem gridRingDaniel">
                        <Ring
                            type="daniel"
                        ></Ring>
                        <PenButton
                            color="neutral"
                            href="https://codepen.io/Arthega/pen/vYvggxj"
                        >
                        </PenButton>
                    </Item>
                </Grid>
                <Grid xs={12}>
                    <Item className="gridItem gridKeyboard">
                        <Keyboard></Keyboard>
                        <Keyboard></Keyboard>
                        <PenButton
                            color="neutral"
                            href="TODO"
                        >
                        </PenButton>
                    </Item>
                </Grid>
            </Grid>
        </div>
    );
}

export default App;

function PenButton(props: any) {
    return (
        <Button
            color={props.color}
            component="a"
            size="sm"
            href={props.href}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138 26" fill="none" stroke="#000"
                 strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round">
                <path
                    d="M80 6h-9v14h9 M114 6h-9 v14h9 M111 13h-6 M77 13h-6 M122 20V6l11 14V6 M22 16.7L33 24l11-7.3V9.3L33 2L22 9.3V16.7z M44 16.7L33 9.3l-11 7.4 M22 9.3l11 7.3 l11-7.3 M33 2v7.3 M33 16.7V24 M88 14h6c2.2 0 4-1.8 4-4s-1.8-4-4-4h-6v14 M15 8c-1.3-1.3-3-2-5-2c-4 0-7 3-7 7s3 7 7 7 c2 0 3.7-0.8 5-2 M64 13c0 4-3 7-7 7h-5V6h5C61 6 64 9 64 13z"/>
            </svg>
        </Button>
    )
}