import React, {ReactElement} from "react";
import "./Ring.css";


function Ring(props: any): ReactElement {

    const ringElements: number = 28;
    const degrees: number = 360;
    const defaultWidth: number = 170;
    const innerStart = () => {
        return degrees / 4;
    };
    const innerEnd = () => {
        return degrees - innerStart();
    };
    const degree = () => {
        return degrees / ringElements;
    }

    const renderRing = (type: string) => {
        return (
            <div className={"ringWrapper " + (type === 'daniel' ? "ring-d" : "ring-l")}>
                <div className="ringWidth"></div>
                <div className="rotate">
                    <div className="ring">
                        {renderElements(type)}
                    </div>
                    <div className="ring">
                        {renderElements(type, true)}
                    </div>
                </div>
            </div>
        )
    };

    const darkenClass = (i: number, useSegmentClasses: boolean): string => {
        let deg: number = degree() * i;
        let classString: string = '';
        if (useSegmentClasses) {
            classString += 'segment' + i;
        }
        if ((deg >= 35 && deg <= 145) || (deg >= 215 && deg <= 325)) {
            classString += ' darken darken' + i;
        }
        return classString;
    }

    const renderEmboss = (i: number, type: string) => {
        if (type === 'daniel' && i === 12) {
            return (
                <div className="emboss">
                    Pl 950
                </div>
            )
        } else if (type === 'lisa' && i === 10) {
            return (
                <div className="emboss">
                    5 8 5
                </div>
            )
        }
        return '';
    }

    const renderElements = (type: string, inner: boolean = false) => {
        let usedWidth: number = defaultWidth;
        if (type === 'lisa') {
            usedWidth -= 10;
        }
        if (inner) {
            usedWidth -= 20;
            if (type === 'lisa') {
                usedWidth += 5;
            }
        }
        let elements = [];
        for (let i: number = 0; i < 28; i++) {
            if (degree() * i >= innerStart() && degree() * i <= innerEnd()) {
                elements.push(
                    <React.Fragment key={"u" + i}>
                        <div
                            className={"segment upperHalf " + darkenClass(i, type === 'lisa')}
                            style={{
                                transform: "rotateY(" + degree() * i + "deg) translateZ(" + usedWidth + "px)"
                            }}
                        >
                            {inner ? renderEmboss(i, type) : ''}
                        </div>
                    </React.Fragment>
                );
            }
        }
        for (let i: number = 0; i < 28; i++) {
            if (degree() * i < innerStart() || degree() * i > innerEnd()) {
                elements.push(
                    <React.Fragment key={"l" + i}>
                        <div
                            className={"segment lowerHalf " + darkenClass(i, type === 'lisa' && !inner)}
                            style={{
                                transform: "rotateY(" + degree() * i + "deg) translateZ(" + usedWidth + "px)"
                            }}
                        >
                        </div>
                    </React.Fragment>
                );
            }
        }
        return elements;
    }

    function renderSvg(i: number) {
        i++;
        return (
            <svg xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter
                        id={"noise-filter" + i}
                        filterUnits="objectBoundingBox"
                        primitiveUnits="userSpaceOnUse"
                        colorInterpolationFilters="linearRGB"
                    >
                        <feTurbulence
                            type="fractalNoise"
                            baseFrequency={0.001 * i}
                            numOctaves={4 + i}
                            seed={15 + i}
                            stitchTiles="stitch"
                            result="turbulence"
                        >
                        </feTurbulence>
                        <feSpecularLighting
                            surfaceScale="20"
                            specularConstant="0.9"
                            specularExponent={20 + i}
                            lightingColor="#999"
                            in="turbulence"
                            result="specularLighting"
                        >
                            <feDistantLight
                                azimuth="3"
                                elevation={55 - i}
                            >
                            </feDistantLight>
                        </feSpecularLighting>
                    </filter>
                </defs>
                <rect
                    className="texture"
                    width="100%"
                    height="100%"
                    filter={"url(#noise-filter" + i + ")"}>
                </rect>
            </svg>
        )
    }

    return (
        <>
            {renderRing(props.type)}
        </>
    )
}

export default Ring;
