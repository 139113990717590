import "./Keyboard.css";
import {ReactElement} from "react";

const firefoxContent: string = "                 ESC                   F1          F2           F3          F4                     F5          F6           F7          F8                    F9          F10        F11         F12 \n \n\n\n\n" +
    "G1              ^            1           2 ²          3 ³           4            5             6           7 {          8 [           9 ]          0 }         ß \\           ´                    🡨                                                                                     /             *             -\n\n\n\n" +
    "G2                  ↹              Q           W            E            R            T             Z            U            I             O           P            Ü          + ~                                                                                     7            8             9   \n\n" +
    "                                                                                                                                                                                                                         ↩                                                                                                             +     \n\n" +
    "G3                 🠗🠗                   A            S            D            F            G            H            J            K             L            Ö           Ä            #                                                                                   4             5            6 \n\n\n\n" +
    "G4                🠕🠕            < |           Y            X            C            V            B            N           M             ,            .             -                         🠕🠕                                           ↑                               1             2            3 \n\n\n\n" +
    "G5                                     ❖                                                                ----                                                               ❖               ▤                                       ←            ↓            →                       0                    ,";
const firefoxContentSmall: string = "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      DRUCK            ROLLEN             PAUSE\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                UNTBR\n\n\n\n\n\n\n\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       EINFG                 POS1                  BILD                          NUM\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ˄\n\n\n\n\n\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ENTF                  ENDE                 BILD\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ˅\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ENTER\n\n\n\n" +
    "                                          STRG                                                                 ALT                                                                                                                                                                                             ALT GR                                                                                                   STRG";
const firefoxSpecials: string = "°                  !               \"                  §                  $                %                &               /                  (                   )                 =                ?                  `\n\n\n\n" +
    "                           @                                   €                                                                                                                                                                        *                                                                                                                  POS1             ↑              BILD  \n\n\n\n" +
    "                                                                                                                                                                                                                                                   ´                                                                                                               ←                                   → \n\n\n\n" +
    "                       >                                                                                                                                    µ                  ;                 :                 _                                                                                                                                        ENDE             ↓              BILD\n\n\n\n" +
    "                                                                                                                                                                                                                                                                                                                                                                         EINFG                  ENTF";


const chromeContent: string = "                  ESC                    F1           F2           F3           F4                     F5           F6           F7           F8                     F9          F10         F11         F12 \n \n\n\n\n" +
    "G1               ^             1            2 ²          3 ³            4             5            6            7 {           8 [           9 ]          0 }           ß \\           ´                    🡨                                                                                          /              *             -\n\n\n\n" +
    "G2                  ↹               Q            W            E             R             T             Z            U              I             O            P             Ü           + ~                                                                                        7             8             9   \n\n" +
    "                                                                                                                                                                                                                                    ↩                                                                                                                 +     \n\n" +
    "G3                  🠗🠗                   A             S             D             F            G             H            J             K             L             Ö            Ä             #                                                                                       4             5             6 \n\n\n\n" +
    "G4                🠕🠕             < |            Y            X             C             V            B             N            M             ,              .              -                         🠕🠕                                             ↑                                 1             2             3 \n\n\n\n" +
    "G5                                       ❖                                                                   ----                                                                   ❖                ▤                                        ←             ↓            →                        0                      ,";
const chromeContentSmall: string = "                                                                                                                                                                                                                                                                                                                                                                                           DRUCK          ROLLEN           PAUSE\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                         UNTBR\n\n\n\n\n\n\n\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                            EINFG              POS1              BILD                      NUM\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                              ˄\n\n\n\n\n\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                             ENTF              ENDE              BILD\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                              ˅\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n" +
    "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ENTER\n\n\n\n" +
    "                                  STRG                                                    ALT                                                                                                                                                        ALT GR                                                                               STRG";
const chromeSpecials: string = "°                 !               \"               §                $               %              &             /                (                 )               =               ?                 `\n\n\n\n" +
    "                         @                               €                                                                                                                                                         *                                                                                                       POS1           ↑             BILD  \n\n\n\n" +
    "                                                                                                                                                                                                                            ´                                                                                                    ←                                → \n\n\n\n" +
    "                    >                                                                                                                        µ                ;                :                _                                                                                                                          ENDE            ↓            BILD\n\n\n\n" +
    "                                                                                                                                                                                                                                                                                                                                      EINFG                 ENTF";


const isFirefox: boolean = ("InstallTrigger" in window);

function Keyboard(): ReactElement {
    let buttons = isFirefox ? firefoxContent : chromeContent;
    let buttonsSmall = isFirefox ? firefoxContentSmall : chromeContentSmall;
    let specials = isFirefox ? firefoxSpecials : chromeSpecials;
    return (
        <div className="keyboardWrapper">
            <div className="keyboard">
                <div className="buttons" buttons-text={buttons} buttons-text-small={buttonsSmall}>
                    {specials}
                </div>
            </div>
        </div>
    )
}


export default Keyboard;