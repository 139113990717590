import "./Mario.css";
import {ReactElement} from "react";

function Mario(): ReactElement {

    const renderBlocks = () => {
        let blocks = [];
        for (let i: number = 0; i < 16; i++) {
            blocks.push(
                <div key={i} className="block"></div>
            )
        }
        return blocks;
    }

    return (
        <div className="marioWorld">
            <div className="ground">
                {renderBlocks()}
            </div>
            <div className="marioWrapper">
                <div className="mario"></div>
            </div>
        </div>
    )
}

export default Mario;